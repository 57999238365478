@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');

/* styles.css */
.future-ecommerce-title {
    font-family: 'Nunito', sans-serif !important;
    font-size: 48px !important;
    font-weight: 700 !important;
    line-height: 65.47px !important;
    text-align: center !important;
    color: #000000 !important; 
}
.future-ecommerce-description {
    font-family: 'Nunito', sans-serif !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 38.4px !important;
    text-align: center !important;
    color: #798889 !important;
}
.future-ecommerce-revolution-title {
    font-family: 'Nunito', sans-serif !important;
    font-size: 48px !important;
    font-weight: 700 !important;
    margin-bottom: 0px !important;
    line-height: 60px !important;
    text-align: center !important;
    color: #000000 !important; 
}

.primary-text {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 27.28px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
  }
  
  .secondary-text {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;
    color: #798889;
  }
  

.customTextField .MuiInputBase-root {
    border-color: #828282 !important;
  }
  
  .customTextField label.Mui-focused {
    color: #828282 !important;
  }
  
  .customTextField .MuiInput-underline:after {
    border-bottom-color: #B2BAC2 !important;
  }
  
  .customTextField .MuiOutlinedInput-root fieldset {
    border-color: #828282 !important;
  }
  
  .customTextField .MuiOutlinedInput-root:hover fieldset {
    border-color: #828282 !important;
  }
  
  .customTextField .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #828282 !important;
  }
  
  .customCancelButton {
    border-radius: 15px !important;
    text-transform: none !important;
    border-color: #232323 !important;
    display: inline-flex !important;
    color: #232323 !important;
    padding: 4px 25px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }
  
  .customCancelButton:hover {
    background-color: none !important;
  }
  
  .customJoinButton {
    background-color: #232323 !important;
    border-radius: 15px !important;
    text-transform: none !important;
    display: inline-flex !important;
    color: #ffffff !important;
    padding: 6px 25px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }
  
  .customJoinButton:hover {
    background-color: #232323 !important;
  }
.successDialogContent {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
    padding: 20px !important;
  }
  
  .successIcon {
    width: 100px !important; 
    height: 100px !important;
    margin-bottom: 20px !important;
  }
  
  .successMessage {
    color: #4CAF50 !important;
    font-weight: bold !important;
    font-size: 18px !important;
  }
  
  .thankYouMessage {
    font-weight: bold !important;
    font-size: 20px !important;
    margin-top: 10px !important;
    color: #333 !important;
  }
  
  .subText {
    color: #666 !important;
    font-size: 16px !important;
  }
  
  .closeButton {
    border-radius: 15px !important;
    text-transform: none !important;
    border-color: #232323 !important;
    display: inline-flex !important;
    color: #232323 !important;
    padding: 6px 25px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
  }
.store-button {
  width: 160px;
  transition: transform 0.3s ease;
  gap: 10px 
}

.store-button:hover {
  transform: scale(1.1);
}


.customSelect .MuiSelect-root {
  border-color: #828282 !important;
}

.customSelect .MuiInputLabel-root.Mui-focused {
  color: #828282 !important;
}

.customSelect .MuiOutlinedInput-notchedOutline {
  border-color: #828282 !important;
}

.customSelect .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #828282 !important;
}

.customSelect .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #828282 !important;
}

.customSelect .MuiSelect-icon {
  color: #828282 !important; /* Change icon color if needed */
}


/* styles.css */

@keyframes pulse {
  0% {
    transform: scale(1);
    background-color: #ffffff;
    color: #232323;
  }
  50% {
    transform: scale(1.05);
    background-color: #232323;
    color: #ffffff;
  }
  100% {
    transform: scale(1);
    background-color: #ffffff;
    color: #232323;
  }
}

.pulsing-button {
  animation: pulse 2s infinite;
}
