/* styles.css or App.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column; 
  min-height: 100vh; 
}

.header {
  background-color: transparent; 
}

.footer {
  margin-top: auto; 
  padding: 40px 20px;
  background-color: #798889; 
  color: #fff; 
  text-align: center;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  padding: 20px;
}

.image {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.testimonials {
  padding: 20px;
}
